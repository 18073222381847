<template>
    <v-dialog v-model="bookingNotification.display" no-click-animation persistent>
    <v-snackbar id="bookingnoti"
        v-if="bookingNotification.display"
        v-model="bookingNotification.display"
        :bottom="snackbar_props.position === 'bottom'"
        :top="snackbar_props.position === 'top'"
        :left="snackbar_props.position === 'left'"
        :right="snackbar_props.position === 'right'"
        :centered="snackbar_props.position === 'centered'"
        :color="snackbar_props.color"
        :timeout="snackbar_props.timeout"
        :multi-line="snackbar_props.mode === 'multi-line'"
        :vertical="snackbar_props.mode === 'vertical'"
        :absolute="snackbar_props.absolute"
        :dark="snackbar_props.theme==='dark'"
        :light="snackbar_props.theme==='light'"
      >
        <v-container pt-2 pb-5 px-8 fluid>
            <v-row justify="center" class="mt-2">
                <template v-if="bookingNotification.context=='success'">
                    <str v-if="!bookingNotification.subcontext" index="event > booked > success noti-enrollment"/>
                    <str v-else-if="bookingNotification.subcontext=='already_enrolled'" index="event > booked > already-enrolled"/>
                </template>
                <template v-if="bookingNotification.context=='failure'">
                    <str index="message > system_unexpected_error"/>
                    <str v-if="bookingNotification.context=='failure'" index="message > try_again"/>
                </template>
            </v-row>

            <template v-if="bookingNotification.context=='success'">
                <v-row v-if="snackbar_props.render_as_html"><span v-html="bookingNotification.event_date"></span></v-row>
                <v-row no-gutters v-else dark class="mt-7 justify-center" >
                    <v-row align="center" style="background:#2b4f7f">
                        <v-col cols="3"><v-icon color="white">mdi-calendar-check-outline</v-icon></v-col>
                        <v-col cols="9" class="white--text">{{bookingNotification.event_date}}</v-col>
                    </v-row>
                    <v-row style="background:white">
                        <v-col cols="3" style="border: 1px solid #f2f2f2"><str index="event > booked > event_title"/></v-col>
                        <v-col cols="9">{{bookingNotification.event_title}}</v-col>
                    </v-row>
                </v-row>
            </template>
        </v-container>
        <template v-slot:action="{ attrs }">
            <v-btn icon style="position: absolute; top:0; right: 2px;" width="24" height="24" color="transparent" @click="closeBookingNoti()" v-bind="attrs">
                <v-icon color="#b5371e" small>mdi-close</v-icon>
            </v-btn>
        </template>     
    </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    name: "BookingNotification",
    data: function(){
        return {
            snackbar_props: {
                theme: 'light',
                color: '#f2f2f2',
                position: 'centered',
                timeout: -1,
                mode: 'multi-line',
                absolute: false,
                render_as_html: false
            }
        }
    },
    methods: {
        closeBookingNoti: function(){
            let cloneBookingNotification = {...(this.bookingNotification)}
            cloneBookingNotification.display = false;
            this.$store.dispatch('bookingNotification', cloneBookingNotification);
            window.location.href = this.$store.getters.baseURL;                                           
        }       
    },
    computed: {
        language: function(){
            return this.$store.getters.language;
        },
        bookingNotification: function(){
            return this.$store.getters.bookingNotification;
        }
    },
    watch: {
        bookingNotification: function(after, before){// eslint-disable-line no-unused-vars
            if (after.display===true){
                /*
                setTimeout( function(){
                    this.closeBookingNoti();
                }.bind(this),this.snackbar_props.timeout)
                 * 
                 */
            }
        }
    }
}
</script>

<style lang="css" scoped>
/deep/.v-snack__wrapper {
    max-width: 320px;
}

</style>

<style>
.v-application--is-ltr .v-snack__action {
    margin-right: 0px;
}

.v-snack__content {
    text-align: center !important;
}
</style>