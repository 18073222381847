import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ApiError from "@/classes/ApiError.js";
import vuetify from "./plugins/vuetify";
import cookie from "./plugins/cookie";

import commonComponents from "./components/Globals/loader";
commonComponents.forEach((component) => {
    Vue.component(component.name, component);
});

Vue.config.productionTip = false;

const methods = {
    /* eslint-disable no-unreachable */
    sendAsyncRequest: async function(data) {
        let action = data.action;
        let call = data.call;
        axios.defaults.headers.post["X-CSRF-Token"] =
            typeof this.$store.getters.csrf == "string" ? this.$store.getters.csrf : false;
        axios.defaults.headers.post["jwt"] =
            typeof this.$store.getters.jwt == "string" ? this.$store.getters.jwt : false;
        axios.defaults.headers.post["language"] =
            typeof this.$store.getters.language == "string" ? this.$store.getters.language : "en";

        try {
            let api = `${this.$store.getters.api}?action=${action}&call=${call}`;
            const response = await axios.post(api, data);
            const result = response.data;

            const isApiNotCsrfRequest = action !== "csrf" && call !== "request";
            const isCsrfCheckFailed = result["csrf-check"].result === false && isApiNotCsrfRequest;
            if (isCsrfCheckFailed) {
                const errorMessage = `Failed in csrf-check`;
                const errorCause = result[action][call];
                throw new ApiError(errorMessage, errorCause);
            }

            const isActionCallFailed = result[action][call]["error"];
            if (isActionCallFailed) {
                const errorMessage = `Failed in api call: ${action} - ${call}`;
                const errorCause = result[action][call]["error"];
                throw new ApiError(errorMessage, errorCause);
            }

            return result[action][call];
        } catch (e) {
            this.handleError(e);
        }
    },

    handleError: function(error) {
        throw error;
    },

    sendRequest: function(data) {
        let self = this;
        axios.defaults.headers.post["X-CSRF-Token"] =
            typeof self.$store.getters.csrf == "string" ? self.$store.getters.csrf : false;
        axios.defaults.headers.post["jwt"] =
            typeof self.$store.getters.jwt == "string" ? self.$store.getters.jwt : false;
        axios.defaults.headers.post["language"] =
            typeof self.$store.getters.language == "string" ? self.$store.getters.language : "en";
        var request = new Promise((resolve) => {
            resolve();
        });

        if (self.$store.getters.csrf || data.action == "csrf") {
            request = new Promise((resolve, reject) => {
                axios
                    .post(self.$store.getters.api + "?action=" + data.action + "&call=" + data.call, data)
                    .then(function(response) {
                        if (data.action == "csrf" && (data.call == "request" || data.call == "validate")) {
                            self.$root.$controller.service.send("session.restart");
                            resolve(response);
                        } else {
                            if (
                                response &&
                                response.data &&
                                response.data["csrf-check"] &&
                                response.data["csrf-check"].result === true
                            ) {
                                self.$root.$controller.service.send("session.restart");
                                resolve(response);
                            } else {
                                console.error(
                                    "There was a security token issue: a new token was requested and retry was attempted"
                                );
                                self.$store.dispatch("clear_csrf");
                                self.$root.$controller.service.send("csrf.request");
                                setTimeout(function() {
                                    self.$root.sendRequest(data).then(
                                        function(response) {
                                            resolve(response);
                                        },
                                        function(response) {
                                            reject(response);
                                        }
                                    );
                                }, 3000);
                            }
                        }
                    });
            });
        } else {
            console.error("NO CSRF");
        }

        return request;
    },

    sendBackgroundRequest: function(data) {
        var self = this;
        axios.defaults.headers.post["X-CSRF-Token"] = self.$store.getters.csrf;
        axios.defaults.headers.post["jwt"] = self.$store.getters.jwt;
        var request = new Promise((resolve) => {
            resolve();
        });

        if (self.$store.getters.csrf || data.action == "csrf") {
            request = new Promise((resolve, reject) => {
                axios
                    .post(self.$store.getters.api + "?action=" + data.action + "&call=" + data.call, data)
                    .then(function(response) {
                        if (response.data["csrf-check"].result === true) {
                            self.$root.$controller.service.send("session.restart");
                            resolve(response);
                        } else {
                            reject(response);
                        }
                    });
            });
        } else {
            console.log("sendBackgroundRequest", "NO CSRF");
        }

        return request;
    },

    /**
     * Show the necessary message in the console within the dev environment only
     * @param {string} message - string message that will show in the log
     * @param {*} data - data that will show in the log
     * @param {boolean} errorSignal
     * @returns
     */
    customCLog: function(message, data = null, errorSignal = false) {
        const isDev = process.env.NODE_ENV == "development";
        if (isDev) {
            // if errorSignal is on (true), then use console.error instead.
            if (errorSignal) {
                console.error(message, data);
                return;
            }
            if (data) {
                console.groupCollapsed(message, data);
                console.trace(message, data);
                console.groupEnd();
                return;
            }

            console.groupCollapsed(message);
            console.trace(message);
            console.groupEnd();
            return;
        }
    },

    labels: function(key) {
        return this.$store.getters.library &&
            this.$store.getters.library.labels &&
            this.$store.getters.library.labels[key]
            ? this.$store.getters.library.labels[key][this.$store.getters.language]
            : null;
    },

    labelTerm: function(index = null) {
        let lang = this.$store.getters.language;
        let labels = this.$store.getters.library ? this.$store.getters.library.labels : {};
        return labels[index] !== undefined && labels[index][lang] !== undefined
            ? labels[index][lang]
            : process.env.NODE_ENV == "development"
            ? "Missing [" + lang + "]::" + index
            : "";
    },

    unlink: function(obj) {
        var object = obj;
        if (typeof obj == "object") {
            object = JSON.stringify(obj);
            object = JSON.parse(object);
        }

        return object;
    },

    user: function() {
        return this.$store.getters.user;
    },

    parseJSON: function(object) {
        for (let key in object) {
            var item = object[key];
            if (typeof item == "string" && item.length >= 2) {
                try {
                    item = JSON.parse(item);
                    object[key] = item;
                } catch (e) {
                    var garbage = e;
                    garbage = null;
                    if (garbage != null) {
                        console.log("garbage", garbage);
                    }
                }
            }
        }
        return object;
    },
    /* eslint-disable no-unused-vars */

    formRules: function(email = null, initialPw = null) {
        function validate_password_strength(password) {
            var pass = password != null ? isStrongPwd(password) : false;

            function isStrongPwd(password) {
                let uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

                let lowercase = "abcdefghijklmnopqrstuvwxyz";

                let digits = "0123456789";

                let splChars = "!@#$%&*()-";

                let ucaseFlag = contains(password, uppercase);

                let lcaseFlag = contains(password, lowercase);

                let digitsFlag = contains(password, digits);

                let splCharsFlag = contains(password, splChars);

                let lengthFlag = password.length >= 6;

                return lengthFlag && ucaseFlag && lcaseFlag && digitsFlag && splCharsFlag;
            }

            function contains(password, allowedChars) {
                for (var i = 0; i < password.length; i++) {
                    var char = password.charAt(i);
                    if (allowedChars.indexOf(char) >= 0) {
                        return true;
                    }
                }

                return false;
            }

            if (pass === false) {
                // this.dialog = true;
            }
            return pass;
        }

        var rules = {
            validFirstName: [
              v => !!v || 'messages > required firstname',
              v => (v || '').trim().length > 0 || 'messages > required firstname'              
            ],
            validLastName: [
              v => !!v || 'messages > required lastname',
              v => (v || '').trim().length > 0 || 'messages > required lastname'              
            ],
            validEmail: [
                (v) => !!v || "messages > required email",
                (v) =>
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v
                    ) || "messages > invalid email",
            ],
            validConfirmEmail: [
                (v) => !!v || "messages > required confirmEmail",
                (v) => v === email || "messages > notMatch emails",
            ],
            validCity: [
              v => !!v || 'messages > required city',
              v => (v || '').trim().length > 0 || 'messages > required city'              
            ],            
            validProvince: [
              v => !!v || 'messages > required province'
            ],
            validPostalCode: [
                (v) => !!v || "messages > required postalCode",
                (v) => /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(v) || "messages > invalid postalcode",
            ],
            validLanguage: [(v) => !!v || "messages > required preferLanguage"],
            validLoginPassword: [(v) => !!v || "messages > required password"],
            validPassword: [
                (v) => !!v || "messages > required password",
                (v) => validate_password_strength(v) || "messages > weak password",
            ],
            validConfirmPassword: [
                (v) => !!v || "messages > required confirmPassword",
                (v) => v === initialPw || "messages > notMatch passwords",
            ],
            validSubject: [(v) => !!v || "messages > required subject"],
            validProgram: [(v) => !!v || "messages > required program"],
            validTitle: [(v) => !!v || "messages > required title"],
            validMessage: [(v) => !!v || "messages > required contactUsMsg"],
            validSpecialty: [(v) => !!v || "messages > required specialty"],
            validConsent: [(v) => v == "Y" || "message > required consent"],
            validProfession: [(v) => !!v || "messages > required profession"],
        };
        // console.log(email, initialPw);
        return rules;
    },

    validEmail: function(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email) {
            return re.test(email);
        }
        return false;
    },

    formatDateString: function(date_string, event_language = null) {
        var date = new Date(date_string);
        var options = {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            timeZone: "America/Toronto",
            timeZoneName: "short",
        };
        var convert_lang;
        if (event_language) {
            convert_lang = event_language == "en" ? "en-CA" : "fr-CA";
        } else {
            convert_lang = this.$store.getters.language == "en" ? "en-CA" : "fr-CA";
        }
        var convert_result = date.toLocaleDateString(convert_lang, options).replace(/\.{1,1}/g, "");
        return convert_result;
    },

    outputDate: function(date_string) {
        var record_dt = new Date(date_string.replace(" ", "T") + "-00:00");

        return {
            record: date_string,
            local: record_dt,
        };
    },
};

function guard(to, from, next){
    var user = store.getters.user;
    var matched = to.matched;
    var requiresAuth = false;
    var restrict_type = false;
    var allow_type = true;
    var triggeredBy = null;
  
    for(var i=0; i<matched.length; i++){
      var route = matched[i];
  
      if(typeof route.meta.requiresAuth!='undefined' && requiresAuth===false){
        requiresAuth = route.meta.requiresAuth;
        restrict_type = (route.meta && route.meta.allow) ? true : false;
        if(requiresAuth===true){
          triggeredBy = route;
        }
      }
    }
  
    if(restrict_type && user){
      allow_type = methods.in_array(user.type,triggeredBy.meta.allow);
    }
  
    if(requiresAuth===false || (requiresAuth===true && user && allow_type)){
      next();
    }else{
      store.dispatch('entryURL',{path:to.fullPath , router:router})
      next('/loguasco');
    }
  }
  
  router.beforeEach(guard);

Vue.mixin({
    methods: methods,
});

let code = new Vue({
    router,
    store,
    vuetify,
    created: () => {
        Vue.prototype.cookie = cookie
    },
    render: (h) => h(App),
}).$mount("#app");

if (location.hostname == "localhost") {
    window.code = code;
} else {
    code = null;
}
