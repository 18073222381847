const provinceList = [
    {text: 'Alberta', value: 'AB'},
    {text: 'British Columbia', value: 'BC'},
    {text: 'Manitoba', value: 'MB'},
    {text: 'New Brunswick', value: 'NB'},
    {text: 'Newfoundland and Labrador', value: 'NL'},
    {text: 'Nova Scotia', value: 'NS'},
    {text: 'Ontario', value: 'ON'},
    {text: 'Prince Edward Island', value: 'PE'},
    {text: 'Quebec', value: 'QC'},
    {text: 'Saskatchewan', value: 'SK'}
]

export default provinceList;