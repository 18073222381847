<template>
<v-container v-if="userList.length">
        <v-row><h3 class="mt-6 mb-2 text-h5 font-weight-medium"><str index="form > title_registrant_information"/></h3></v-row>
        
        <v-form ref='registrationForm' v-model="registrationFormValid"  v-on:submit.prevent='registerUser'>
            <TextInput @input="form.firstname=$event" value="firstname" labelIndex="form > firstname" :rule="'validFirstName'"/>          
            <TextInput @input="form.lastname=$event" value="lastname" labelIndex="form > lastname" :rule="'validLastName'"/>
            <TextInput @input="form.email=$event" value="email" labelIndex="form > email" :rule="'validEmail'"/>
            <TextInput @input="form.confirm_email=$event" value="confirmEmail" labelIndex="form > confirm_email" :rule="'validConfirmEmail'" :email="form.email"/>
            <TextInput @input="form.city=$event" value="city" labelIndex="form > city" :rule="'validCity'"/>         
            <DropdownInput @input="form.province=$event" category="province" value="province" labelIndex="form > province" :rule="'validProvince'" />
            <DropdownInput @input="form.specialty=$event" category="speciality" value="speciality" labelIndex="form > specialty" :rule="'validSpecialty'" />
        </v-form>

        <v-row class="mt-6"><div v-html="labelTerm('site_regulation > usage_concent')" style="font-size:0.825rem; color:#808080;"></div></v-row>
        
        <v-row justify="end" class="mt-6">
            <v-btn dark @click="registerUser()" color="#2b4f7f" class="text-h6">
                <str index="button > submit var1" />
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */ 
    import TextInput from "@/components/Inputs/TextInput.vue"
    import DropdownInput from '@/components/Inputs/DropdownInput.vue'
    export default {
        name: 'registration-form',
        props: {
            getAllUsers: {
                type: Function,
                required: true
            }
        },
        components: {
            TextInput,
            DropdownInput
        },
        data() {
            return {
                form: {
                    firstname: null,
                    lastname: null,
                    email: null,
                    confirm_email: null,
                    city: null,
                    province: null,
                    speciality: null
                },
                registrationFormValid: false,
                userList: []
            }
        },
        created: function(){
            let self = this;
            this.getAllUsers().then(function(response){
                self.userList = response;
            });
        },
        methods: {
            registerUser: function() {
                if(this.$refs.registrationForm.validate()) {
                    console.log("Form is Validated");
                    let self = this;
                    this.$emit("registerUser", {form: this.form, callTyp: this.userList.filter(elem => elem.email===self.form.email).length > 0 ? "book" : "create_user"});
                }
                else {
                    return console.log("Form Validation Failure");
                }
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>