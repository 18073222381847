<template>
    <v-row>
        <v-select :items="dropdownList" @change="$emit('input', $event)" :label="labelTerm(labelIndex)" :rules="formRules()[rule]">
            <template v-slot:selection="{item}">
                <!-- HTML that describe how select should render selected items -->
                <span v-html="item.text"></span>
            </template>
            <template v-slot:message="{ message }">
                <str :index="message" />
            </template>               
        </v-select>
    </v-row>
</template>

<script>
    import provinceList from "@/js/provinceList.js"
    import specialityList from "@/js/specialityList.js"
    export default {
        name: "DropdownInput",
        props: {
            category: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: true
            },
            labelIndex: {
                type: String,
                required: true
            },
            rule: {
                type: String,
                required: false
            }
        },
        computed: {
            dropdownList() {
                const categoryMap = {
                    'province': provinceList,
                    'speciality': specialityList
                }

                return categoryMap[this.category] || [];
            }
        }
    }
</script>

<style lang="css" scoped>
/deep/ label::before {
  content: "*  ";
  color: red;
}

/deep/ .theme--light.v-label {
    color: #9e9e9e;
}
</style>