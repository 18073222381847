<template>
    <v-app>
        <v-main>
            <router-view v-if="ready" />
        </v-main>
    </v-app>
</template>

<script>
/* eslint-disable no-unused-vars */
import Vue from "vue";
import { Machine, interpret } from "xstate"; //assign, sendParent, spawn, raise, actions, send, respond

export default {
    name: "App",

    created: function() {
        let component = this;

        const csrf = new (function() {
            this.request = function(component, context) {
                return new Promise((resolve, reject) => {
                    if (component.sendRequest) {
                        component
                            .sendRequest({
                                action: "csrf",
                                call: "request",
                            })
                            .then(
                                function(response) {
                                    let token = response.data.csrf.request.token;
                                    component.$store.dispatch("csrf", token);
                                    resolve();
                                },
                                function(response) {
                                    reject(response);
                                }
                            );
                    } else {
                        setTimeout(function() {
                            context.csrf.value = "simulated-csrf";
                            resolve();
                        }, 500);
                    }
                });
            };

            this.validate = function(component, context) {
                return new Promise((resolve, reject) => {
                    if (component.sendRequest) {
                        component
                            .sendRequest({
                                action: "csrf",
                                call: "validate",
                            })
                            .then(
                                function(response) {
                                    if (response.data["csrf-check"].result === true) {
                                        resolve();
                                    } else {
                                        component.$store.dispatch("clear_csrf");
                                        reject();
                                    }
                                },
                                function() {
                                    reject();
                                }
                            );
                    } else {
                        setTimeout(function() {
                            context.csrf.value = "simulated-csrf-validated";
                            resolve();
                        }, 500);
                    }
                });
            };
        })();

        const jwt = new (function() {
            this.validate = function(component, context) {
                return new Promise((resolve, reject) => {
                    if (component.sendRequest) {
                        if (component.$store.getters.jwt) {
                            component
                                .sendRequest({
                                    action: "user",
                                    call: "validate",
                                })
                                .then(
                                    function(response) {
                                        if (response.data.user.validate.pass === true) {
                                            resolve();
                                        } else {
                                            component.$store.dispatch("clear_jwt").then(function() {
                                                reject();
                                            });
                                        }
                                    },
                                    function() {
                                        reject();
                                    }
                                );
                        } else {
                            reject();
                        }
                    } else {
                        setTimeout(function() {
                            if (context.authenticate.result) {
                                context.jwt.value = "simulated-jwt-validated";
                                resolve();
                            } else {
                                context.jwt.value = null;
                                reject();
                            }
                        }, 500);
                    }
                });
            };

            this.logout = function(component, context) {
                return new Promise((resolve, reject) => {
                    let action = "login";
                    let call = "logout";

                    if (component.sendRequest) {
                        component
                            .sendRequest({
                                action: action,
                                call: call,
                            })
                            .then(
                                function() {
                                    resolve();
                                },
                                function(response) {
                                    context.error = response;
                                    reject();
                                }
                            );
                    } else {
                        setTimeout(function() {
                            if (context.authenticate.result) {
                                resolve();
                            } else {
                                reject();
                            }
                        }, 500);
                    }
                });
            };

            this.refresh = function() {
                return new Promise((resolve, reject) => {
                    let action = "forms";
                    let call = "refresh";
                    if (component.sendRequest) {
                        component
                            .sendRequest({
                                action: action,
                                call: call,
                                user_id: component.$store.getters.user.id,
                            })
                            .then(
                                function(response) {
                                    if (response.data[action][call].token) {
                                        let token = response.data[action][call].token;
                                        component.$store.dispatch("jwt", token);
                                        resolve(token);
                                    } else {
                                        reject();
                                    }
                                },
                                function() {
                                    reject();
                                }
                            );
                    } else {
                        resolve();
                    }
                });
            };
        })();

        const platformDataHandler = new (function() {
            this.fetch = new (function() {
                this.programs = function(component, context) {
                    return new Promise((resolve, reject) => {
                        let action = "program";
                        let call = "fetch_programs";

                        if (component.sendRequest) {
                            component
                                .sendRequest({
                                    action: action,
                                    call: call,
                                    //autoload: 'yes'
                                })
                                .then(
                                    function(response) {
                                        let programs = response.data[action][call].results;
                                        for (let i = 0; i < programs.length; i++) {
                                            programs[i] = component.parseJSON(programs[i]);
                                        }

                                        component.$store.dispatch("programs", programs);
                                        context.error.platformDataHandler = null;
                                        resolve();
                                    },
                                    function(response) {
                                        context.error.platformDataHandler = response;
                                        reject();
                                    }
                                );
                        } else {
                            setTimeout(function() {
                                resolve();
                            }, 1000);
                        }
                    });
                };

                this.library = function(component, context) {
                    return new Promise((resolve, reject) => {
                        if (component.sendRequest) {
                            component
                                .sendRequest({
                                    action: "str",
                                    call: "library",
                                    //autoload: 'yes'
                                })
                                .then(
                                    function(response) {
                                        context.error.platformDataHandler = null;
                                        component.$store.dispatch("library", response.data.str.library);
                                        resolve();
                                    },
                                    function(response) {
                                        context.error.platformDataHandler = response;
                                        reject();
                                    }
                                );
                        } else {
                            setTimeout(function() {
                                resolve();
                            }, 1000);
                        }
                    });
                };
            })();
        })();

        let machineConfig = {
            id: "app",
            context: {
                error: {
                    app: null,
                    csrf: null,
                    jwt: null,
                    platformDataHandler: null,
                },
            },
            type: "parallel",
            states: {
                app: {
                    initial: "idle",
                    states: {
                        idle: {},
                        ready: {},
                        fetch: {},
                    },
                },
                ui: {
                    id: "ui",
                    type: "parallel",
                    states: {
                        login: {
                            id: "login",
                            initial: "hide",
                            states: {
                                show: {},
                                hide: {},
                            },
                            on: {
                                "ui.login.show": "#ui.login.show",
                                "ui.login.hide": "#ui.login.hide",
                            },
                        },
                    },
                },
                csrf: {
                    id: "csrf",
                    initial: "init",
                    states: {
                        init: {
                            invoke: {
                                src: () =>
                                    new Promise((resolve, reject) => {
                                        if (component.cookie && component.cookie.get("csrf")) {
                                            let token = component.cookie.get("csrf");
                                            component.$store.dispatch("csrf", token);
                                            resolve();
                                        } else {
                                            reject();
                                        }
                                    }),
                                onDone: {
                                    target: "#csrf.validate",
                                },
                                onError: {
                                    target: "#csrf.request",
                                },
                            },
                        },
                        request: {
                            invoke: {
                                src: (context) =>
                                    new Promise((resolve, reject) => {
                                        csrf.request(component, context).then(
                                            function() {
                                                resolve();
                                            },
                                            function() {
                                                reject();
                                            }
                                        );
                                    }),
                                onDone: {
                                    target: "#csrf.validate",
                                },
                                onError: {
                                    target: "#csrf.init",
                                },
                            },
                        },
                        validate: {
                            invoke: {
                                src: (context) =>
                                    new Promise((resolve, reject) => {
                                        csrf.validate(component, context).then(
                                            function() {
                                                resolve();
                                            },
                                            function() {
                                                reject();
                                            }
                                        );
                                    }),
                                onDone: {
                                    target: [
                                        "#csrf.valid",
                                        "#fetch.library.active",
                                        "#fetch.programs.active",
                                    ],
                                },
                                onError: {
                                    target: "#csrf.request",
                                },
                            },
                        },
                        valid: {
                            invoke: {
                                src: () =>
                                    new Promise((resolve) => {
                                        resolve();
                                    }),
                                onDone: {
                                    target: "#jwt.init",
                                },
                            },
                        },
                        error: {},
                    },
                    on: {
                        "csrf.request": "#csrf.request",
                    },
                },
                session: {
                    id: "session",
                    initial: "idle",
                    states: {
                        idle: {
                            invoke: {
                                src: () =>
                                    new Promise((resolve) => {
                                        component.stop_session().then(function() {
                                            resolve();
                                        });
                                    }),
                            },
                            on: {
                                "session.start": "#session.start",
                            },
                        },
                        start: {
                            invoke: {
                                src: () =>
                                    new Promise((resolve) => {
                                        component.start_session().then(function() {
                                            resolve();
                                        });
                                    }),
                                onDone: {
                                    target: "#session.active",
                                },
                            },
                        },
                        restart: {
                            invoke: {
                                src: () =>
                                    new Promise((resolve) => {
                                        component.restart_session().then(function() {
                                            resolve();
                                        });
                                    }),
                                onDone: {
                                    target: "#session.active",
                                },
                            },
                        },
                        active: {
                            on: {
                                "session.restart": "#session.restart",
                                "session.stop": "#session.idle",
                            },
                        },
                    },
                },
                jwt: {
                    id: "jwt",
                    initial: "idle",
                    states: {
                        idle: {},
                        init: {
                            invoke: {
                                src: () =>
                                    new Promise((resolve, reject) => {
                                        if (component.cookie && component.cookie.get("jwt")) {
                                            let token = component.cookie.get("jwt");
                                            component.$store.dispatch("jwt", token);
                                            resolve();
                                        } else {
                                            reject();
                                        }
                                    }),
                                onDone: {
                                    target: "#jwt.validate",
                                },
                                onError: {
                                    target: ["#jwt.idle"],
                                },
                            },
                        },
                        validate: {
                            invoke: {
                                src: (context) =>
                                    new Promise((resolve, reject) => {
                                        jwt.validate(component, context).then(
                                            function() {
                                                resolve();
                                            },
                                            function() {
                                                reject();
                                            }
                                        );
                                    }),
                                onDone: {
                                    target: ["#jwt.valid", "#ui.login.hide"],
                                },
                                onError: {
                                    target: ["#jwt.idle", "#ui.login.show"],
                                },
                            },
                        },
                        valid: {
                            invoke: {
                                src: () =>
                                    new Promise((resolve) => {
                                        if (component.$store.getters.entryURL) {
                                            component.$router.push(component.$store.getters.entryURL.path);
                                            component.$store.dispatch("entryURL", null);
                                        }
                                        resolve();
                                    }),
                                onDone: {
                                    target: "#session.start",
                                },
                            },
                        },
                        logout: {
                            invoke: {
                                src: (context) =>
                                    new Promise((resolve, reject) => {
                                        component.$store.dispatch("clear_jwt");
                                        jwt.logout(component, context).then(
                                            function() {
                                                resolve();
                                            },
                                            function() {
                                                reject();
                                            }
                                        );
                                    }),
                                onDone: {
                                    target: ["#jwt.idle", "#session.idle"],
                                },
                            },
                        },
                        refresh: {
                            invoke: {
                                src: () =>
                                    new Promise((resolve, reject) => {
                                        jwt.refresh().then(
                                            function() {
                                                resolve();
                                            },
                                            function() {
                                                reject();
                                            }
                                        );
                                    }),
                                onDone: {
                                    target: ["#session.active"],
                                },
                            },
                        },
                    },
                    on: {
                        "jwt.init": "#jwt.init",
                        "jwt.logout": "#jwt.logout",
                        "jwt.refresh": "#jwt.refresh",
                    },
                },
                fetch: {
                    id: "fetch",
                    type: "parallel",
                    states: {
                        programs: {
                            initial: "idle",
                            states: {
                                idle: {},
                                error: {},
                                active: {
                                    invoke: {
                                        src: (context) =>
                                            new Promise((resolve, reject) => {
                                                platformDataHandler.fetch.programs(component, context).then(
                                                    function() {
                                                        resolve();
                                                    },
                                                    function() {
                                                        reject();
                                                    }
                                                );
                                            }),
                                        onDone: {
                                            target: "#fetch.programs.idle",
                                        },
                                        onError: {
                                            target: "#fetch.programs.error",
                                        },
                                    },
                                },
                            },
                        },
                        library: {
                            initial: "idle",
                            states: {
                                idle: {},
                                ready: {},
                                error: {},
                                active: {
                                    invoke: {
                                        src: (context) =>
                                            new Promise((resolve, reject) => {
                                                platformDataHandler.fetch.library(component, context).then(
                                                    function() {
                                                        resolve();
                                                    },
                                                    function() {
                                                        reject();
                                                    }
                                                );
                                            }),
                                        onDone: {
                                            target: "#fetch.library.ready",
                                        },
                                        onError: {
                                            target: "#fetch.library.error",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    on: {
                        "fetch.programs": "#fetch.programs.active",
                        "fetch.library": "#fetch.library.active",
                    },
                },
            },
        }; //end machineConfig declaration

        const machine = Machine(machineConfig, {
            guards: {
                allow_comms: function(context) {
                    return component && component.$store
                        ? component.$store.getters.csrf != null
                        : context.csrf.value != null;
                },
                allow_login: function(context) {
                    return component && component.$store
                        ? component.$store.getters.jwt === null
                        : context.jwt.value === null;
                },
                allow_logout: function(context) {
                    return component && component.$store
                        ? component.$store.getters.jwt != null
                        : context.jwt.value != null;
                },
            },
        });

        this.service = interpret(machine);
        this.state = machine.initialState;
        this.context = machine.context;

        let self = this;
        this.service
            .onTransition((state) => {
                self.state = state.value;
                self.context = state.context;
            })
            .start();

        Vue.prototype.$controller = this;
    },
    data: () => ({
        debug: false,
        popups: {
            login: false,
        },
        service: null,
        state: null,
        context: null,
        session: null,
        config: {
            session_timeout: 2 * 60 * 60 * 1000,
        },
        login_trigger_timeout: null,
    }),
    methods: {
        start_session: function() {
            return new Promise((resolve) => {
                let self = this;
                clearTimeout(self.session);
                self.session = setTimeout(function() {
                    self.service.send("jwt.logout");
                }, self.config.session_timeout);
                resolve();
            });
        },
    },
    computed: {
        ready: function() {
            return (
                this.state &&
                this.state.csrf == "valid" &&
                    this.state.fetch.library == "ready" &&
                    this.state.fetch.programs == "idle" &&
                    (this.state.jwt == "idle" || this.state.jwt == "valid")
            );
        },
        jwt: function() {
            return this.$store.getters.jwt;
        },
    },
};
</script>
