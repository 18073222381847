<template>
  <v-row
    :justify="$vuetify.breakpoint.lgAndUp ? 'end' : 'center'"
    :class="['mt-1', { ['image-masked']: program.state === 'closed' || program.state === 'default-closed' }, $vuetify.breakpoint.xs===false ? 'mx-0' : 'mx-0']"
  >
    <img :src="getEventImgURL" alt="event banner" class="side-bar-image" :width="$vuetify.breakpoint.lgAndUp ? 'auto' : '100%'" />
  </v-row>
</template>

<script>
export default {
name: "SideBar",
props: {
    program: {
      type: Object,
    },
},
methods: {
    getEventDesktopImgUrl() {
        try {
            return require.context("@/assets/event/", false, /\.png$/)(`./event_${this.program.id}.png`);
        } catch (ex) {
            return require("@/assets/logo_cdn_night.png");
        }
    },
    getEventMobileImgUrl() {
        try {
            if (this.$vuetify.breakpoint.smAndUp) {
                return require.context("@/assets/event/", false, /\.png$/)(`./event_mobile_${this.program.id}.png`);
            }
            else if(this.$vuetify.breakpoint.xs){
                return require.context("@/assets/event/", false, /\.png$/)(`./event_mobile_xs_${this.program.id}.png`);
            }
            else {
                return require.context("@/assets/event/", false, /\.png$/)(`./event_mobile_xs${this.program.id}.png`);
            }
        } catch (ex) {
            return require("@/assets/logo_cdn_night.png");
        }
    },
},
computed: {
    getEventImgURL() {
        if (this.$vuetify.breakpoint.mdAndUp) {
            return this.getEventDesktopImgUrl();
        } else {
            return this.getEventMobileImgUrl();
        }
    },
},
};
</script>

<style lang="css" scoped>
.image-masked {
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4));
}

.side-bar-image {
  height: auto;
  max-width: 100%;
  max-height: 1100px;
}
</style>