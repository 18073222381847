<template>
  <div class="page-not-found">
    <h1 class="title">Page Not Found</h1>
    <div>
      <HomeButton />
    </div>
  </div>
</template>

<script>
import HomeButton from "@/components/Buttons/HomeButton.vue";
export default {
  name: "PageNotFound",
  components: {
    HomeButton,
  },
};
</script>

<style scoped>
.page-not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  color: #2b507f;
}
</style>
