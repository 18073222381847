<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../../assets/logo_cdn_night.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4" v-if="program.details.closure_content">
        <h1 class="display-2 font-weight-bold mb-3">
            {{program.details.closure_content.title[$store.getters.language]}}
        </h1>

        <p class="headline font-weight-regular">
           {{program.details.closure_content.p1[$store.getters.language]}}
        </p>
        <p class="headline font-weight-regular">
            {{program.details.closure_content.p2[$store.getters.language]}}
        </p>
      </v-col>
      <v-col class="mb-4" v-else>
        <h1 class="display-2 font-weight-bold mb-3">
            Event dated on {{program.start }}.
        </h1>
      </v-col>      
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'event-closure',
    props: {
        program: {
            type: Object
        }
    },
    data: () => ({
    }),
  }
</script>
