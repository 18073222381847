import Vue from "vue";
import VueRouter from "vue-router";
import Registration from "../views/Registration.vue";
import PageNotFound from "@/views/PageNotFound";
import Admin from "@/views/Admin";
import AdminLogin from "@/components/Admin/AdminLogin.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "*",
        name: "PageNotFound",
        component: PageNotFound,
    },
    {
        path: "/",
        alias: "/register",
        name: "Registration",
        component: Registration,
        children: [{ path: "/register/:program_id" }],
    },
    {
        path: "/adminasco",
        name: "Admin",
        component: Admin,
        meta: { requiresAuth: true },
    },
    {
        path: "/loguasco",
        name: "AdminLogin",
        component: AdminLogin,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
