<template>
    <div class="admin-login-wrappper">
        <h3>
            Admin Login
        </h3>
        <div class="mt-6 admin-login-form-wrapper">
            <v-form ref="adminLoginForm" v-model="adminLoginFormValid" v-on:submit.prevent="login">
                <TextInput
                    @input="loginForm.email = $event"
                    value="email"
                    labelIndex="form > email"
                    :rule="'validEmail'"
                />
                <TextInput
                    @input="loginForm.password = $event"
                    value="lastname"
                    labelIndex="form > password"
                    :rule="'validPassword'"
                    :isPassword="true"
                />
            </v-form>
        </div>

        <div class="mt-5">
            <v-btn
                :loading="isCallingApi"
                :disabled="isCallingApi"
                :dark="!isCallingApi"
                depressed
                @click="login()"
                color="#2b4f7f"
                class="text-h6"
            >
                <str index="button > login" />
            </v-btn>
        </div>

        <v-dialog
            v-model="loginNotificationSignal"
            max-width="500"
            height="500"
            no-click-animation
            persistent
        >
            <v-card
                class="d-flex flex-column align-center justify-center mx-auto"
                min-height="300"
                min-width="300"
            >
                <h5 class="login-notification-close-title">
                    {{ loginNotificationMessage.title }}
                </h5>
                <p class="login-notification-close-content">
                    {{ loginNotificationMessage.content }}
                </p>
                <div class="login-notification-close-btn-section">
                    <v-btn outlined small @click="closeLoginNotification()">
                        <str index="button > close" />
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import TextInput from "@/components/Inputs/TextInput.vue";
export default {
    name: "AdminLogin",
    components: {
        TextInput,
    },
    data() {
        return {
            adminLoginFormValid: false,
            isCallingApi: false,
            loginForm: {
                email: null,
                password: null,
            },
            loginNotificationSignal: false,
            loginNotificationMessage: {
                title: "",
                content: "",
            },
        };
    },
    methods: {
        async login() {
            if (!this.$refs.adminLoginForm.validate()) {
                return;
            }
            this.isCallingApi = true;
            let apiData = {
                action: "user",
                call: "login",
                email: this.loginForm.email,
                password: this.loginForm.password,
            };
            let loginResult = await this.sendAsyncRequest(apiData);
            if (loginResult.LoginError === true) {
                delete loginResult.LoginError;
                let loginErrors = [];
                for (const [key, value] of Object.entries(loginResult)) {
                    if (value === false) {
                        loginErrors.push(key);
                    }
                }
                this.handleLoginFailure(loginErrors);
            } else {
                this.handleLoginSuccess(loginResult.jwt);
            }
            this.isCallingApi = false;
            return;
        },
        handleLoginFailure(loginErrors) {
            let isLoginAccountReachMaxAttemptOnly =
                loginErrors.includes("allowAttempt") && loginErrors.length === 1;
            if (isLoginAccountReachMaxAttemptOnly) {
                this.loginNotificationMessage.title = "Reach Maximum Attempt";
                this.loginNotificationMessage.content = "Please contact the IT admin for further support.";
            } else {
                this.loginNotificationMessage.title = "Invalid username or password";
                this.loginNotificationMessage.content = "Please try it again.";
            }
            this.loginNotificationSignal = true;
            return;
        },
        handleLoginSuccess(jwt) {
            this.$store.dispatch("jwt", jwt).then(() => {
                this.$router.push("adminasco");
            });
        },
        closeLoginNotification() {
            this.loginNotificationSignal = false;
        },
    },
};
</script>

<style scoped>
.admin-login-form-wrapper {
    width: 100%;
    max-width: 300px;
}

.admin-login-wrappper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-notification-close-title {
    font-size: 22px;
    font-weight: 500;
}
</style>
