<template>
    <div>
        <template>
            <AdminUserDashboard />
        </template>
    </div>
</template>

<script>
import AdminUserDashboard from "@/components/Admin/AdminUserDashboard";
export default {
    name: "Admin",
    components: {
        AdminUserDashboard,
    },
};
</script>

<style></style>
