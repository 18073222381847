<template>
    <v-row>
        <v-text-field
            :label="labelTerm(labelIndex)"
            :ref="value"
            validate-on-blur
            :rules="formRules(email)[rule]"
            :type="inputType"
            @change="$emit('input', $event)"
        >
            <template v-slot:message="{ message }">
                <str :index="message" />
            </template>
        </v-text-field>
    </v-row>
</template>

<script>
export default {
    name: "TextInput",
    props: {
        value: {
            required: true,
        },
        labelIndex: {
            type: String,
            required: true,
        },
        rule: {
            type: String,
            required: false,
        },
        email: {
            type: String,
            required: false,
        },
        isPassword: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        onInputChange: function(event) {
            console.log(event);
        },
    },
    computed: {
        inputType() {
            if (this.isPassword === true) {
                return "password";
            } else {
                return "text";
            }
        },
    },
};
</script>

<style lang="css" scoped>
/deep/ label::before {
    content: "*  ";
    color: red;
}

/deep/ .theme--light.v-label {
    color: #9e9e9e;
}
</style>
