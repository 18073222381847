const specialityList = [
    {text: 'Urology', value: 'urology'},
    {text: 'Uro-Oncology', value: 'uro-oncology'},
    {text: 'Medical Oncology', value: 'medical-oncology'},
    {text: 'Radiation Oncology', value: 'radiation-oncology'},
    {text: 'Internal Medicine', value: 'internal-medicine'},
    {text: 'Nurse', value: 'nurse'},
    {text: 'Other', value: 'other'}
]

export default specialityList;